
import { defineComponent } from 'vue'

export default defineComponent({
	props: {
		language: String
	},
	data: () => {
		return {
			times: [
				{
					title: '20',
					active: 0,
					media: [
						'videos/SEO_0058.mp4',
						'videos/SEO_0059.mp4',
						'images/ultrasounds/30-08-2021/30-08-2021_01.jpg',
						'images/ultrasounds/30-08-2021/30-08-2021_02.jpg',
						'images/ultrasounds/30-08-2021/30-08-2021_03.jpg',
						'images/ultrasounds/30-08-2021/30-08-2021_04.jpg',
						'images/ultrasounds/30-08-2021/30-08-2021_05.jpg',
					],
				},
				{
					title: '14',
					active: 0,
					media: [
						'images/ultrasounds/23-07-2021/23-07-2021_01.jpg',
						'images/ultrasounds/23-07-2021/23-07-2021_02.jpg',
						'images/ultrasounds/23-07-2021/23-07-2021_03.jpg'
					]
				},
				{
					title: '12',
					active: 0,
					media: [
						'images/ultrasounds/07-07-2021/07-07-2021_01.jpg',
						'images/ultrasounds/07-07-2021/07-07-2021_02.jpg',
						'images/ultrasounds/07-07-2021/07-07-2021_03.jpg'
					]
				},
				{
					title: '9',
					active: 0,
					media: [
						'images/ultrasounds/23-06-2021/23-06-2021_01.jpg',
						'images/ultrasounds/23-06-2021/23-06-2021_02.jpg',
						'images/ultrasounds/23-06-2021/23-06-2021_03.jpg'
					]
				},
			]
		}
	},
	methods: {
		setActiveMedia (iTime, iItem) {
			this.times[iTime].active = iItem
		}
	}
})
