<template>
	<p
		v-if="language != 'en'"
		class="language-picker button"
		:class="classFromParent"
		@click="setLanguage('en')">
		Prefer English?
	</p>
	<p
		v-if="language != 'nl'"
		class="language-picker button"
		:class="classFromParent"
		@click="setLanguage('nl')">
		Liever Nederlands?
	</p>
</template>

<script>
export default {
	name: 'language-picker',
	props: {
		language: String,
		classFromParent: String,
	},
	data: () => {
		return {
		}
	},
	methods: {
		setLanguage (val) {
			this.$emit('update-language', val)
		}
	}
}
</script>

<style scoped lang="scss">
.language-picker.button {
	position: absolute;
	top: 40px;
	left: 40px;
	max-width: 50px;
	text-align: center;
	font-size: 90%;

	&:hover {
		font-weight: bold;
	}
}
</style>
