<template>
	<!-- <h2>Photo Gallery</h2> -->
	<div class="photo-gallery-box">
		<GalleryPhoto
			v-for="photo in photos"
			:key="photo.path"
			:photo="photo" />
	</div>
</template>

<script>
import GalleryPhoto from '@/components/basic/GalleryPhoto.vue'

export default {
	name: 'photo-gallery',
	components: {
		GalleryPhoto
	},
	props: {
		photos: Array
	}
}
</script>

<style lang="scss" scoped>
.photo-gallery-box {
	display: grid;
	grid-row-gap: 20px;
	margin: 40px auto;

	@include min-m {
		grid-template-columns: repeat(3, 1fr);
		grid-column-gap: 20px;
		width: 90%;
		max-width: 1200px;
	}
}
</style>
