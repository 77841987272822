<template>
	<router-link to="/">
		<img src="@/assets/images/stamp_post_global.svg" class="button postcard-icon">
	</router-link>
	<h1>Sneak peak</h1>
	<PhotoGallery :photos="photos" />
</template>

<script>
import PhotoGallery from '@/components/basic/PhotoGallery.vue'

export default {
	components: {
		PhotoGallery
	},
	data: () => {
		return {
			photos: [
				{
					path: 'photos/04.jpeg',
				},
				{
					path: 'photos/09.jpeg',
				},
				{
					path: 'photos/02.jpeg',
				},
				{
					path: 'photos/06.jpeg',
				},
				{
					path: 'photos/07.jpeg',
				},
				{
					path: 'photos/05.jpeg',
				},
				{
					path: 'photos/01.jpeg',
				},
				{
					path: 'photos/03.jpeg',
				},
				{
					path: 'photos/08.jpeg',
				},
				{
					path: 'photos/10.jpeg',
				},
				{
					path: 'photos/11.jpeg',
				}
			]
		}
	}
}
</script>

<style lang="scss" scoped>
h1 {
	@include max-s {
		padding-top: 100px;
	}
}
.postcard-icon {
	position: absolute;
	left: 40px;
	width: 60px;
	transform: rotate(90deg);

	filter: invert(75%) sepia(42%) saturate(604%) hue-rotate(165deg) brightness(91%) contrast(88%);

	&:hover {
		filter: invert(38%) sepia(87%) saturate(385%) hue-rotate(151deg) brightness(96%) contrast(92%);
	}
}
</style>
