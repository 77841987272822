<template>
	<router-view />
</template>

<style lang="scss">
$blue-baby: #ABD9EB;

html {
	-webkit-tap-highlight-color: transparent;
}
body {
	margin: 0;
}
#app {
  font-family: 'Source Sans Pro', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
h1 {
	font-size: 100px;
	margin: 20px 20px 0 20px;

	@include max-s {
		font-size: 60px;
	}
}

p {
	margin: 4px;
}
a,
.hyperlink {
	text-decoration: none;
	color: darken($blue-baby, 14%);
	font-weight: bold;

	&:hover {
		color: darken($blue-baby, 40%);
		cursor: pointer;
	}
}
.handwritten {
	font-family: 'Mansalva', cursive;

	&.header {
		font-size: 32px;
		margin: 8px;
	}
}
.white-text {
	color: white;
}
.second-name {
	display: inline-block;
	font-size: 140%;
	font-weight:  bold;
}
.last-name {
	display: inline-block;
	font-size: 140%;
	font-weight:  bold;
}
/* Elements */
.button {
	cursor: pointer;
}
</style>
