<template>
	<div class="photo-box">
		<img :src="require(`@/assets/images/${photo.path}`)" class="photo">
		<p v-if="photo.description" class="description">
			{{ photo.description }}
		</p>
	</div>
</template>

<script>
export default {
	name: 'gallery-photo',
	props: {
		photo: Object
	}
}
</script>

<style lang="scss" scoped>
.photo {
	max-width: 100%;
}
</style>
