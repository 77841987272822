<template>
	<div class="home">
		<LanguagePicker
			:language="language"
			:class-from-parent="{'white-text': !isBorn}"
			@update-language="language = $event" />
		<BirthAnnouncement
			v-if="isBorn"
			:language="language" />
		<BeforeBirth v-else :language="language" />
		<div class="button born" @click="isBorn = !isBorn">
			<p v-if="isBorn">
				<img class="active" src="@/assets/images/eye_open_black.svg">
				<img class="hover" src="@/assets/images/eye_closed_black.svg">
			</p>
			<p v-else>
				<img class="active" src="@/assets/images/eye_closed_white.svg">
				<img class="hover" src="@/assets/images/eye_open_white.svg">
			</p>
		</div>
	</div>
</template>

<script>
// import { Options, Vue } from 'vue-class-component'
import BirthAnnouncement from '@/components/view/BirthAnnouncement.vue'
import BeforeBirth from '@/components/view/BeforeBirth.vue'
import LanguagePicker from '@/components/basic/LanguagePicker.vue'

export default {
	components: {
		BirthAnnouncement,
		BeforeBirth,
		LanguagePicker
	},
	data: () => {
		return {
			isBorn: true,
			language: 'nl'
		}
	},
}
// @Options({
// 	components: {
// 		BirthAnnouncement,
// 		BeforeBirth
// 	}
// })

// const HomeTest = {
// 	data() {
// 		return {
// 			isBorn: true
// 		}
// 	}
// }

// export default class Home extends Vue {}
</script>

<style scoped lang="scss">
.button.born {
	position: absolute;
	top: 40px;
	right: 40px;

	p {
		height: 60px;
		width: 100px;

		@include max-s {
			width: 60px;
		}
	}

	img {
		width: 100%;
	}

	img.hover {
		display: none;
	}

	&:hover {
		img.active {
			display: none;
		}
		img.hover {
			display: block;
		}
	}
}
</style>
