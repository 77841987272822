

export default {
	props: {
		language: String
	},
	data: () => {
		return {
			showMeaning: false
		}
	},
	methods: {
		toggleMeaning () {
			// this.showMeaning != this.showMeaning
		}
	}
}
